import React from "react";
import useClientTranslation from "../hooks/useClientTranslation";
import usePlatformLanguage from "../hooks/usePlatformLanguage";

const ServiceNotSupported = () => {
  const { language } = usePlatformLanguage();
  const { translation } = useClientTranslation(language);

  return (
    <div className="flex items-center justify-center flex-col w-full min-h-screen text-white">
      <h3 dangerouslySetInnerHTML={{ __html: translation.LoadingView.ServiceNotSupported }} />
    </div>
  );
};

export default ServiceNotSupported;
