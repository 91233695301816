import { FullScreenHandle } from "react-full-screen";

export default function FullScreenBtn({ handle }: { handle: FullScreenHandle }) {
  return (
    <button
      className="absolute top-20 right-80 md:top-30 md:right-100 w-50 h-50 bg-ui-600 flex items-center justify-center rounded-10 text-primary hover:bg-ui transition duration-200"
      id="fullScreenBtn"
      onClick={handle.enter}
    >
      <img className="w-20 h-20" src="/icons/fullscreen.svg" alt="Fullscreen Button" />
    </button>
  );
}
