import {
  InputEmitter,
  StreamerStatus
} from '@pureweb/platform-sdk';

import { useEffect } from 'react'
import { Subject } from 'rxjs';

const VALUE_LISTENER = 'logout'

export const useUnrealLogoutListener = (emitter: InputEmitter, streamerStatus: StreamerStatus, messageSubject: Subject<string>, callback: Function) => {

  useEffect(() => {
    const subscription = messageSubject.subscribe(
      (value: string) => {
        try {
          let json = JSON.parse(value)

          if(json.type === VALUE_LISTENER) {
            console.log("Incoming Call from UnrealLogout: ", value)

            callback()
          }
        } catch(e){
          console.error(e)
        }        
      },
      (err: any) => {
        console.error(err);
      }
    );
    
    return () => {
      subscription.unsubscribe();
    };
  }, [messageSubject, emitter, streamerStatus, callback]);
}