import React from "react";
import IconCheck from "./IconCheck";
import IconUnchecked from "./IconUnchecked";
export default class Button extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };

    this.icon = this.icon.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  icon() {
    return this.state.checked ? <IconCheck /> : <IconUnchecked />;
  }

  toggle(event) {
    event.preventDefault();
    this.setState(function (state, props) {
      return {
        checked: !state.checked,
      };
    });
  }

  render() {
    return (
      <button
        className="checkbox bg-transparent border-none flex outline-none mr-10 cursor-pointer items-center text-gray-light hover:text-gray focus:text-gray transition ease-in-out duration-200 rounded focus:outline-brown"
        onClick={this.toggle}
        role="checkbox"
        aria-checked="false"
      >
        <div aria-hidden="true" className="mr-10">
          {this.icon()}
        </div>

        <div aria-hidden="true" className="pt-2 leading-snug">
          {this.props.children}
        </div>
      </button>
    );
  }
}
