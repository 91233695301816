import crypto from 'crypto-browserify'
import base64url from 'base64url'

export default class PKCE {
    static generateCodeVerifier(): string {
        return require("randomstring").generate(128);
    }
  
    static async generateCodeChallengeFromVerifier(verifier: string) {
        let base64digest = crypto.createHash("sha256").update(verifier).digest("base64")
        return base64url.fromBase64(base64digest);
    }
}