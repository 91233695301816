import SupportedLanguage from "../helpers/SupportedLanguages"
import client from '../client.json'
import { useEffect, useState } from "react";


const useClientTranslation = (lang: SupportedLanguage) => {
    const [isTranslationSet, setIsTranslationSet] = useState<boolean>(false)
    const [translation, setTranslation] = useState(client.translation[lang])

    useEffect(() => {
        setTranslation(client.translation[navigator.language === 'de-DE' ? SupportedLanguage.de : SupportedLanguage.en])
        setIsTranslationSet(true);
    }, [])

    return { isTranslationSet, translation }
}

export default useClientTranslation