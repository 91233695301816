import React from 'react'
import { useAkamaiAuthentication } from "./useAkamaiAuthentication";
import { useAuth0Authentication } from "./useAuth0Authentication";
import { useConverveAuthentication } from "./useConverveAuthentication";
import useGrayconTickets, { Status } from "./useGrayconTickets";
import useLocalAuth from './useLocalAuth';
import { usePingAuthentication } from "./usePingAuthentication";
// import { AuthState } from '@aws-amplify/ui-components';

/**
 * Enum for AuthenticationStates
 * - Gray Convention GmbH Token (GC)
 * - Converve GmbH
 * - Akamai Redirection
 * - LocalAuth
 * - LocalAuth with Code
 * - None
 */
export enum AuthenticationState {
    // basic options
    NONE = 'none',
    AKAMAI = 'akamai',
    AUTH0 = 'auth0',
    LOCAL = 'local',

    // rarely used options
    AMPLIFY = 'amplify', // amplify === cognito === aws
    CONVERVE = 'converve',
    CVENT = 'cvent',
    GC = 'graycon',
    LOCAL_WITH_CODE = 'localWithCode',
    PING = 'ping',

    // not used yet
    GOOGLE = 'google'
}

interface IAuthentication {
    authenticated: boolean,
    status?: Status | undefined,
    logout: () => void,
    bearer: string,
    loading: boolean,
    check: (code: string) => Promise<void> | void
}


const useAuthenticationSwitch = (state: AuthenticationState): IAuthentication => {
    // AKAMAI
    const { isAuthenticated,
        bearer,
        logout } = useAkamaiAuthentication(state === AuthenticationState.AKAMAI)

    // AUTH0
    const { isAuthenticated: auth0Authenticated, loading: auth0Loading, logout: logoutAuth0 } = useAuth0Authentication(state === AuthenticationState.AUTH0)

    // CONVERVE
    const { isAuthenticated: converveAuthenticated, logout: logoutConverve } = useConverveAuthentication(state === AuthenticationState.CONVERVE)

    // GC
    const { authenticated: gcAuthenticated,
        check,
        loading: gcLoading,
        status: gcStatus,
    } = useGrayconTickets(state === AuthenticationState.GC, "https://gray-staging-api.herokuapp.com")

    // LOCAL
    const { isAuthenticated: isLocalAuthenticated, logout: localAuthLogout } = useLocalAuth()

    // PING
    const { isAuthenticated: pingAuthenticated,
        bearer: pingBearer,
        logout: logoutPing } = usePingAuthentication(state === AuthenticationState.PING)

    // const [authState, setAuthState] = React.useState<AuthState>();


    if (state === AuthenticationState.AKAMAI) {
        // Authentication for AKAMAI        
        return {
            authenticated: isAuthenticated(),
            bearer,
            check: (code: string) => { },
            loading: false,
            logout,
            status: Status.OK
        }
    } else if (state === AuthenticationState.AUTH0) {
        // Authentication for AUTH0
        return {
            authenticated: auth0Authenticated,
            bearer: "",
            check: () => { },
            loading: auth0Loading,
            logout: logoutAuth0,
            status: Status.OK,
        }
    } else if (state === AuthenticationState.CONVERVE) {
        // Authentication for CONVERVE
        return {
            authenticated: converveAuthenticated(),
            bearer: "",
            check: () => { },
            loading: false,
            logout: logoutConverve,
            status: Status.OK,
        }
    } else if (state === AuthenticationState.CVENT) {
        // Authentication for CVENT
        return {
            authenticated: isLocalAuthenticated,
            bearer: "",
            check: (code: string) => { },
            logout: localAuthLogout,
            loading: false,
            status: Status.OK,
        }
    } else if (state === AuthenticationState.GC) {
        // Authentication for GC
        return {
            authenticated: gcAuthenticated,
            bearer: "",
            check,
            loading: gcLoading,
            logout: () => { },
            status: gcStatus,
        }
    } else if (state === AuthenticationState.LOCAL) {
        // Authentication for LOCAL
        return {
            authenticated: isLocalAuthenticated,
            bearer: "",
            check: (code: string) => { },
            loading: false,
            logout: localAuthLogout,
            status: Status.OK,
        }
    } else if (state === AuthenticationState.LOCAL_WITH_CODE) {
        // Authentication for LOCALWITHCODE
        return {
            authenticated: isLocalAuthenticated,
            bearer: "",
            check: (code: string) => { },
            loading: false,
            logout: localAuthLogout,
            status: Status.OK,
        }

    } else if (state === AuthenticationState.PING) {
        // Authentication for PING
        return {
            authenticated: pingAuthenticated(),
            bearer: pingBearer,
            check: (code: string) => { },
            loading: false,
            logout: logoutPing,
            status: Status.OK,
        }
    } else {
        // default authentication
        return {
            authenticated: true,
            bearer: "",
            check: () => { },
            loading: false,
            logout: () => { },
            status: Status.OK,
        }
    }
}

export default useAuthenticationSwitch;