import { useEffect, useState } from "react";
import Cookies, { CookieSetOptions } from 'universal-cookie';

const HEADER_OPTIONS = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "graycon-request-url": 'Noys.live',
    "graycon-request-pathname": '/'
};

export enum Status {
    OK = 200,
    UNAUTHORIZED = 401,
    NOT_FOUND = 404,
    ERROR = 1000
}

const IDENTIFIER = 'GC_CODE'

/**
 * useGrayconTicket to check Ticket Code
 * @returns {check}
 */
const useGrayconTickets = (enabled: boolean, url: string = 'https://gray-staging-api.herokuapp.com') => {
    const [loading, setLoading] = useState<boolean>(false)
    const [authenticated, setAuthenticated] = useState<boolean>(false)
    const [status, setStatus] = useState<Status | undefined>()

    const cookie_options: CookieSetOptions = {
        path: '/',
        secure: true,
        expires: new Date(Date.now() + 3600000)
    }


    const check = async (code: string) => {

        let init: RequestInit = {
            method: "GET",
            headers: HEADER_OPTIONS,
            credentials: "same-origin"
        }

        try {
            setLoading(true)

            let route = url + "/api/v1/login"
            let response = await fetch(route + "?value=" + code.toLowerCase(), init);

            setStatus(response.status as Status)
            setLoading(false)

            if (response.status === Status.OK) {
                setCode(code)
            }
        } catch (error) {
            setLoading(false)
            setStatus(Status.ERROR)
        }
    }

    const setCode = (code: string) => {
        let cookies = new Cookies()
        cookies.set(IDENTIFIER, true, cookie_options)
    }

    const codeValid = (): boolean => {
        let cookies = new Cookies()

        return Boolean(cookies.get(IDENTIFIER))
    }


    useEffect(() => {
        if (!enabled) return
        setAuthenticated(codeValid())
    }, [enabled])


    return { authenticated, check, loading, status }
}

export default useGrayconTickets;