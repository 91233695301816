import { Director, Publish } from "@millicast/sdk";

const useMillicast = (emitter) => {
  /**
   *
   * @param {*} publishingToken
   * @param {*} streamName
   * @param {*} sharingMode
   */

  const start = (publishingToken, streamName, sharingMode = "screen") => {
    console.log("Start - Screen: ", sharingMode === "screen", " Webcam: ", sharingMode === "webcam");

    //Define callback for generate new tokens
    const tokenGenerator = () =>
      Director.getPublisher({
        token: publishingToken,
        streamName: streamName,
      });

    //Create a new instance
    window.millicastPublish = new Publish(streamName, tokenGenerator);

    if (sharingMode === "screen") {
      const constraints = {
        width: 1920,
        height: 1080,
        video: { cursor: "always" },
        audio: {
          echoCancellation: false,
          noiseSuppression: false,
          sampleRate: 44100,
        },
        frameRate: { max: 30 },
        aspectRatio: 1.777777778,
      };
      //Get User camera and microphone
      navigator.mediaDevices
        .getDisplayMedia(constraints)
        .then((mediaStream) => {
          window.stream = mediaStream;

          mediaStream.getVideoTracks()[0]?.addEventListener("ended", () => {
            stop();
            sendStop(streamName);
          });

          const connectOptions = {
            mediaStream: mediaStream,
            bandwidth: 0,
            disableVideo: false,
            disableAudio: false,
            codec: "vp8",
            simulcast: false,
            scalabilityMode: null,
            peerConfig: {},
          };

          window.millicastPublish.connect(connectOptions);
        })
        .catch((err) => {
          // send message 'millicast_cancel' to unreal if share screen dialog is cancelled
          console.log(err);
          sendCancel(streamName);
        });
    } else if (sharingMode === "webcam") {
      const constraints = {
        audio: true,
        video: true,
      };
      //Get User camera and microphone
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((mediaStream) => {
          window.stream = mediaStream;

          mediaStream.getVideoTracks()[0]?.addEventListener("ended", () => {
            stop();
            sendStop(streamName);
          });

          const connectOptions = {
            mediaStream: mediaStream,
            bandwidth: 0,
            disableVideo: false,
            disableAudio: false,
            codec: "vp8",
            simulcast: false,
            scalabilityMode: null,
            peerConfig: {},
          };

          window.millicastPublish.connect(connectOptions);
        })
        .catch((err) => {
          // send message 'millicast_cancel' to unreal if share screen dialog is cancelled
          sendCancel(streamName);
        });
    }
  };

  /**
   * stop all sharing Tracks and stop millicast connection
   */
  const stop = () => {
    window.stream.getTracks().forEach((track) => track.stop());
    window.millicastPublish.stop();
  };

  /**
   * send cancel message and streamID to unreal
   */
  const sendCancel = (streamID) => {
    emitContent({ type: "millicast_cancel", streamid: streamID });
  };

  /**
   * send stop message and streamID to unreal
   */
  const sendStop = (streamID) => {
    emitContent({ type: "millicast_stop", streamid: streamID });
  };

  /**
   * send message to unreal
   * @param content Object
   */
  function emitContent(content) {
    emitter.EmitUIInteraction(content);
  }

  return { start, stop };
};

export default useMillicast;
