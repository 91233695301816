import { useAuth0 } from "@auth0/auth0-react";
import useClientTranslation from "../hooks/useClientTranslation";
import usePlatformLanguage from "../hooks/usePlatformLanguage";

const Auth0LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  const { language } = usePlatformLanguage();
  const { translation } = useClientTranslation(language);

  return (
    <button onClick={() => loginWithRedirect()} id="loginbutton" className="btn btn-brown md:max-w-1/2">
      {translation.launch.actions.login}
    </button>
  );
};

export default Auth0LoginButton;
