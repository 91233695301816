import { useEffect, useState } from "react";
import { InputEmitter, StreamerStatus } from "@pureweb/platform-sdk";
import { Api, createIdentity, Identity, ConnectParameters, TSServerConnectionWebRTC } from "teamspeak-remoteapps-ts";
import { AudioState } from "../state/audio";
import { Subject } from "rxjs";
import { Confirm } from "semantic-ui-react";
import { System } from "@pureweb/platform-sdk-react";

require("dotenv").config();

const VALUE_LISTENER = "InitializeTeamSpeak";


interface connectionParams {
  nickname: string,
  address: {
    host: string,
    port: number
  },
  password: string
}

interface StreamProps {
  emitter: InputEmitter;
  messageSubject: Subject<string>;
  streamerStatus: StreamerStatus;
}

export default function UETS(props: StreamProps) {
  let webClient: any;
  const [audioState, setAudioState] = useState(() => new AudioState());
  const [clientId, setClientId] = useState<string>();
  const [initTSStringReceived, setInitTSStringReceived] = useState<boolean>(false);
  const [connectionParams, setConnectionParams] = useState<connectionParams>();
  const info = System.Browser();
  const isSafari = info.name === "safari";
  let connectionParamsSet = false; // keep track of whether connection params have been set

  const [state, setState] = useState<{ open: boolean; result: string }>({ open: false, result: "null" });
  const show = () => setState({ open: true, result: "shown" });
  const handleConfirm = () => {
    setState({ result: "confirmed", open: false })
    initTeamspeak();
  };
  const handleCancel = () => setState({ result: "cancelled", open: false });

  useEffect(() => {
    const cannotEmit = (streamerStatus: StreamerStatus, emitter: InputEmitter): Boolean => {
      return !emitter || streamerStatus !== StreamerStatus.Connected;
    };

    if (cannotEmit(props.streamerStatus, props.emitter)) return;

    const subscription = props.messageSubject.subscribe(
      (value: string) => {
        console.log("ts is connection params set", connectionParamsSet);
        if (connectionParamsSet) return;
        
        try {
          let json = JSON.parse(value);
          if (json.type === VALUE_LISTENER && (json.TSIP && json.TSPORT && json.TSNAME && json.TSPW)) {
            setConnectionParams({
              nickname: json.TSNAME,
              address: {
                host: json.TSIP,
                port: parseInt(json.TSPORT)
              },
              password: json.TSPW
            });
            connectionParamsSet = true;
          }
        } catch (e) {
          console.error(e);
        }
      },
      (err: any) => {
        console.error(err);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [props.messageSubject, state]);

  useEffect(() => {
    if (initTSStringReceived) {

    }
  },[initTSStringReceived]);

  useEffect(() => {
    const emitContent = (content: any) => {
      props.emitter.EmitUIInteraction(content);
    };

    if (clientId) {
      console.log('send TS client id to unreal. clientID: ' + clientId);
      emitContent({
        WebClientId: clientId
      });
    }
  }, [clientId]);

   // New gain subscription 
  useEffect(() => {
      const subscription = props.messageSubject.subscribe(
        (value: string) => {
          try {
            let json = JSON.parse(value);
            if (json.ClientGain !== undefined) {
              console.log("CHANGED GAIN", json.ClientGain);
              updateCaptureGain(parseFloat(json.ClientGain));
            }
          } catch (e) {
            console.error(e);
          }
        },
        (err: any) => {
          console.error(err);
        }
      );
  
      return () => {
        subscription.unsubscribe();
      };
  }, [props.messageSubject]);

  const initTeamspeak = async () => {
    const tmpSingleServer = new TSServerConnectionWebRTC(0);
    tmpSingleServer.refreshBindings();
    webClient = tmpSingleServer;

    const connectStatusHandler = (ev: any) => {
      console.log("checking connection status");
      if (ev.status === Api.ConnectStatus.ConnectionEstablished) {
        console.log("connection established");
        if (webClient.clientId) {
          setClientId(webClient.clientId);
          console.log("client id set");
        }
        tmpSingleServer.subscribeAll();
      }
    };

    const createNewIdentity = new Promise<Identity>((resolve, reject) => {
      createIdentity()
        .then((identity) => {
          resolve(identity);
        })
        .catch(() => {
          reject();
        });
    });

    const params = new ConnectParameters({
      identity: await createNewIdentity,
      nickname: connectionParams?.nickname,
      address: {
        host: connectionParams?.address.host,
        port: connectionParams?.address.port,
      },
      password: connectionParams?.password,
    });

    const connect = async () => {
      let attempts = 0;
      const MAX_ATTEMPTS = 2; // maximum number of connection attempts
      const DELAY_BETWEEN_ATTEMPTS = 1000; // delay in milliseconds
    
      const tryConnect = async () => {
        await audioState?.reinitMedia();
        if (audioState?.captureGainNode) {
          params.captureNode = audioState.captureGainNode;

        }
        params.audioContext = audioState?.audioContext ?? undefined;
        console.log(audioState);
        console.log("Connection status: ", webClient.connectionStatus);
    
        // WebClient instantiated and connected => disconnect
        if (webClient && webClient.connectionStatus !== Api.ConnectStatus.Disconnected) {
          disconnect();
        }
        
        // WebClient instantiated, but not connected => connect
        webClient.events.connectStatusChanged.off(connectStatusHandler);
        webClient.events.connectStatusChanged.on(connectStatusHandler);
    
        webClient.connect(params.finalize());
        // Check the connection status after a delay
        //setTimeout(checkConnection, DELAY_BETWEEN_ATTEMPTS);
      };
    
      // Check the connection status
      const checkConnection = () => {
        if (webClient.connectionStatus === Api.ConnectStatus.ConnectionEstablished) {
          console.log("connection established");
          if (webClient.clientId) {
            setClientId(webClient.clientId);
            console.log("client id set");
          }
          tmpSingleServer.subscribeAll();
        } else if (attempts < MAX_ATTEMPTS) {
          console.log('Connection attempt failed, retrying...');
          attempts++;
          setTimeout(tryConnect, DELAY_BETWEEN_ATTEMPTS);
        } else {
          console.log('Max connection attempts reached');
          connectionParamsSet = false;
        }
      };
    
      tryConnect(); // initial connection attempt
    };
    
    const disconnect = () => {
      tmpSingleServer?.disconnect();
      console.log("disconnect2");
    };

    connect();
  }

  // change gain 
  const updateCaptureGain = (gainValue: number) => {
    console.log(audioState);
    if (audioState?.captureGainNode) {
      audioState.captureGainNode.gain.setValueAtTime(gainValue, audioState.captureGainNode.context.currentTime);
    } else {
        console.error('audioState or someMethodOrProperty not available');
    }
}

  useEffect(() => {
    if (connectionParams) {
      console.log("useeffectloop checking for connectionparams",connectionParams);
      if (isSafari) {
        //show()
        initTeamspeak();
      } else {
        initTeamspeak();
      }
      
    }
  },[connectionParams]);

  return (
    <Confirm
      open={isSafari && state.open}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      content="Do you want to start the stream?"
      cancelButton="No"
      confirmButton="Yes"
    />
  );
}