import {
  InputEmitter,
  StreamerStatus
} from '@pureweb/platform-sdk';

import { useEffect } from 'react'
import { Subject } from 'rxjs';

const VALUE_LISTENER = 'endReactLoadingScreen'
const VALUE_LISTENER2 = 'startReactLoadingScreen'

export const useUnrealLoadingScreenListener = (emitter: InputEmitter, streamerStatus: StreamerStatus, messageSubject: Subject<string>, callback: (value: boolean, value2: boolean) => void) => {

  useEffect(() => {
    const subscription = messageSubject.subscribe(
      (value: string) => {
        try {
          let json = JSON.parse(value)

          if(json.type === VALUE_LISTENER) {
            console.log("Incoming Call from UnrealLoadingView: ", value)

            callback(true, false)
          } else if (json.type === VALUE_LISTENER2) {
            console.log("Incoming Call from UnrealLoadingView: ", value)
            callback(false, true)
          }
        } catch(e){
          console.error(e)
        }        
      },
      (err: any) => {
        console.error(err);
      }
    );
    
    return () => {
      subscription.unsubscribe();
    };
  }, [messageSubject, emitter, streamerStatus, callback]);
}