import { useEffect } from 'react'

export const useGameMessages = (messageSubject: any, callback: Function) => {
    // Subscribe to game messages
    useEffect(() => {
        const subscription = messageSubject.subscribe(
            (value: string) => {
                callback(value)
            },
            (err: any) => {
                console.error(err);
            }
        );

        return () => {
            subscription.unsubscribe();
        };
    }, [messageSubject, callback]);
}