import jwt_decode from "jwt-decode";
import ConverveParticipant from "./ConverveParticipant";
import Cookies, { CookieSetOptions } from 'universal-cookie';

/**
 * Converve Authentication
 */
export default class ConverveAuth {

    AES256 = 32 // 256 Bits
    cookie_options: CookieSetOptions = { path: '/',
                                         secure: true, 
                                         expires: new Date(Date.now() + 3600000) }

    private readonly _participant: ConverveParticipant | undefined
    private readonly _valid: boolean
    private readonly _code: string

    constructor(code: string){
        this._valid = true
        this._code = code
    
        try {

            /**
             * Check Content
             */
            let object: any = jwt_decode(code)
            
            /**
             * Check Header
             */
            jwt_decode(code, { header: true })

            this._participant = new ConverveParticipant(object["participant"])

            /**
             * Save Participant in Cookies
             */
            this.saveParticipant()

            this.saveCode()
        } catch(e){
            this._valid = false
        }
    }

    get participant(): ConverveParticipant | undefined {
        return this._participant;
    }

    get valid(): boolean {
        return Boolean(this._participant) && this._valid
    }

    static get valid(): boolean {
        return Boolean(ConverveAuth.participant)
    }

    static get code(): string {
        let cookies = new Cookies()
        let code = cookies.get("converveCode")

        return code
    }

    /**
     * Clean Up
     */
    static cleanUp() {
        let cookies = new Cookies()
        cookies.remove("participant")
        cookies.remove("converveCode")
    }

    private saveParticipant() {
        if(!this._participant) return

        let cookies = new Cookies()

        cookies.set("participant", this._participant?.toJson(), this.cookie_options)
    }

    private saveCode(){
        if(!this._code) return

        let cookies = new Cookies()
        cookies.set("converveCode", this._code, this.cookie_options)
    }

    static get participant(): ConverveParticipant {
        let cookies = new Cookies()
        let participantJSON = cookies.get("participant")

        return new ConverveParticipant(participantJSON)
    }
}