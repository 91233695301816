import { useLayoutEffect, useRef, useState } from "react";
import clsx from "clsx";

interface ScrollViewProps {
  children: JSX.Element;
  isViewUpdated?: boolean;
  outerStyles?: string;
  innerStyles?: string;
  view?: string;
}

export default function ScrollViewWrapper(props: ScrollViewProps) {
  const [containerFlexCenter, setContainerFlexCenter] = useState<boolean>(false);
  const isLoadingView = props?.view && props.view === "loading";
  const outerContainerStyle = clsx(
    (containerFlexCenter && isLoadingView) && " md:h-screen md:items-center",
    (containerFlexCenter && !isLoadingView) && " md:items-center",
    (!containerFlexCenter && isLoadingView) && " md:flex md:h-auto",
    (!containerFlexCenter && !isLoadingView) && " md:block md:h-screen md:overflow-y-auto",
  );
  const outerContainer = useRef<HTMLDivElement>(null);
  const innerContainer = useRef<HTMLDivElement>(null);
  const outerStyles = props.outerStyles ? " " + props.outerStyles : "";
  const innerStyles = props.innerStyles ? " " + props.innerStyles : "";

  useLayoutEffect(() => {
    // scroll to page top if view switches between login and register view
    if (outerContainer?.current && innerContainer?.current) {
      setTimeout(() => window.scrollTo(0, 0), 100);
      outerContainer.current.scrollTop = 0;
    }

    const resizeHandler = () => {
      if (innerContainer?.current) {
        window.innerHeight < innerContainer.current.clientHeight ? setContainerFlexCenter(false) : setContainerFlexCenter(true);
      }
    };

    window.addEventListener("resize", resizeHandler);
    resizeHandler();
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [props.isViewUpdated]);

  return (
    <div ref={outerContainer} className={`relative flex flex-1 md:flex-initial${outerContainerStyle}${outerStyles}`}>
      <div ref={innerContainer} className={`px-6 py-40 ${innerStyles}`}>
        {props.children}
      </div>
    </div>
  );
}
