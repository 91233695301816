import {
  InputEmitter,
  StreamerStatus
} from '@pureweb/platform-sdk';

import { useEffect } from 'react'
import { Subject } from 'rxjs';
import AkamaiAuth from '../helpers/AkamaiAuth';

require('dotenv').config()

const VALUE_LISTENER = 'tokenExchange'

interface UnrealTokenExchangeContent {
    BEARER: string,
    metaverseType: string
}

export const useUnrealTokenExchange = (emitter: InputEmitter, streamerStatus: StreamerStatus, messageSubject: Subject<string>, bearer: string) => {

  useEffect(() => {
    const emitContent = (content: UnrealTokenExchangeContent) => {
      emitter.EmitUIInteraction(content)
    }
  
    const canEmit = (streamerStatus: StreamerStatus, emitter: InputEmitter): Boolean => {
      return Boolean(bearer) && (!emitter || streamerStatus !== StreamerStatus.Connected)
    }

    if (canEmit(streamerStatus, emitter)) return;

    const subscription = messageSubject.subscribe(
      (value: string) => {

        try {
          let json = JSON.parse(value)

          if(json.type === VALUE_LISTENER) {
            let bearer = AkamaiAuth.getBearer()
            let metaverseType = process.env.REACT_APP_METAVERSE_TYPE as string
            emitContent({ BEARER: bearer, metaverseType: metaverseType })
          }
        } catch(e){

        }        
      },
      (err: any) => {
        console.error(err);
      }
    );
    
    return () => {
      subscription.unsubscribe();
    };
  }, [messageSubject, emitter, streamerStatus, bearer]);

}