import { useState} from 'react'
import useAsyncEffect from "use-async-effect";
import AkamaiAuth from '../helpers/AkamaiAuth';

export const useAkamaiAuthentication = (enabled: boolean) => {

  const akamai = AkamaiAuth.default()
  const [bearer, setBearer] = useState(AkamaiAuth.getBearer())

  function isAuthenticated(akamai: AkamaiAuth): boolean {
    return akamai.isAuthenticated()
  }

  useAsyncEffect(async () => {
    if(!enabled || isAuthenticated(akamai)) return

    
    if(akamai.isCancelUrl()){
      alert("You cancelled the login process!")
      window.location.href = window.location.origin

      return
    }

    if(akamai.isRedirectUrl()){

      let state = akamai.getStateFromUrl()

      if(!akamai.isStateValid(state)) { AkamaiAuth.cleanUp(); window.location.href = window.location.origin }
    
      let object = await akamai.authenticate(akamai.getCode())

      if(!akamai.isNonceValid(object)) { AkamaiAuth.cleanUp(); window.location.href = window.location.origin }

        /**
         * If there were any Errors during the authentication process => cleanup and restart
         */
        if(!Boolean(object.authToken)){ AkamaiAuth.cleanUp(); window.location.href = window.location.origin }
  
        setBearer(object.authToken)
    } else {
      /**
        * If User is not authenticated => redirect to Authorize Page from Akamai
        */
      if(!akamai.isAuthenticated()) {
      await akamai.redirectToLogin()
        return 
      }
    }
  }, [])

  return { isAuthenticated: () => isAuthenticated(akamai), bearer, cleanUp: () => AkamaiAuth.cleanUp(), logout: () => akamai.logout() }
}