export default class UrlHelper {
  url = "";

  constructor(url: string) {
    this.url = url;
  }

  getParams() {
    let retParameters: Record<string, any> = {};
    let params: string = this.url.split("?")[1]; // String

    if (params === undefined) return retParameters;

    params.split("&").reduce((acc, param) => {
      let t = param.split("=");
      acc[t[0]] = t[1].replace(/['"]+/g, "");
      return acc;
    }, retParameters);

    return retParameters;
  }

  hasParams() {
    return Object.keys(this.getParams()).length > 0;
  }
}
