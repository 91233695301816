import { useState, useEffect } from 'react';
import logger from "../Log";
import { useQuery } from "./useQuery";
import ClientOptions from './../ClientOptions';
import {  Resolution } from '@pureweb/platform-sdk';
// import {
//   Resolution
// } from '@pureweb/platform-sdk';

import client from '../client.json'

export const useClientOptions = (): any => {
  const [clientData, setClientData] = useState({})

  const query = useQuery()
    
  const parseClient = (client: any) => {
    const clientOptions: ClientOptions = new ClientOptions();
    clientOptions.LaunchType = (query['launchType'] as string) ?? client.launchType;
    clientOptions.Endpoint = (query['endpoint'] as string) ?? client.endpoint;
    clientOptions.ProjectId = (query['projectId'] as string) ?? client.projectId;
    clientOptions.Version = (query['version'] as string) ?? client.version;
    clientOptions.ModelId = (query['modelId'] as string) ?? client.modelId;
    clientOptions.EnvironmentId = (query['environmentId'] as string) ?? client.environmentId;
    clientOptions.Resolution = (query['resolution'] as Resolution) ?? client.resolution;
    clientOptions.UsePointerLock = query['usePointerLock'] !== undefined ?? false;
    clientOptions.PointerLockRelease =
      (query['pointerLockRelease'] === undefined ? client.pointerLockRelease : query['pointerLockRelease'] === 'true') ?? false;

    clientOptions.ForceRelay = query['forceRelay'] !== undefined ?? client.forceRelay ?? false;
    clientOptions.UseNativeTouchEvents = (query['useNativeTouchEvents'] === undefined ? client.useNativeTouchEvents : query['useNativeTouchEvents'] === 'true') ?? false;
    clientOptions.regionOverride = query['regionOverride'] as string;
  
    // clientOptions.virtualizationProviderOverride = query['virtualizationProviderOverride'] as string;

    clientOptions.iframeUrl = query['iframeUrl'] as string ?? client.iframeUrl

    logger.info(clientOptions, client)

    return clientOptions
  }

  useEffect(() => {
    if(!client) return
    setClientData(parseClient(client) as any)
  }, [client])

  return clientData
}