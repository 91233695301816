import { useEffect } from "react";
import { InputEmitter, StreamerStatus } from "@pureweb/platform-sdk";

interface SendAudioProps {
  emitter: InputEmitter;
  streamerStatus: StreamerStatus;
  microphone: string;
}

const useSendAudioFrames = (props: SendAudioProps) => {
  // Send audio frames
  useEffect(() => {
    const PCMAudioMessageType = 2;
    if (!props.emitter || props.streamerStatus !== StreamerStatus.Connected) return;
    if (process.env.REACT_APP_VOICE === "TS") return;

    (window as any).AudioContext = (window as any).AudioContext || (window as any).webkitAudioContext;
    var audio_context = new AudioContext({ sampleRate: 16000 });
    var processor: ScriptProcessorNode;

    var constraints = { audio: { deviceId: props.microphone ? {exact: props.microphone} : undefined, channelCount: 1, sampleRate: 16000, noiseSuppression: true, echoCancellation: true } };
    navigator.mediaDevices.getUserMedia(constraints).then((ms) => {
      let source = audio_context.createMediaStreamSource(ms);

      let gainNode = audio_context.createGain();

      source.connect(gainNode);

      processor = audio_context.createScriptProcessor(1024, 1, 1);
      source.connect(processor);

      const downsample = (ev: AudioProcessingEvent) => {
        const sampleRate = ev.inputBuffer.sampleRate;
        const buffer = ev.inputBuffer.getChannelData(0);

        if (16000 === sampleRate) {
          return ev.inputBuffer.getChannelData(0);
        }
        var sampleRateRatio = sampleRate / 16000;
        var newLength = Math.round(buffer.length / sampleRateRatio);
        var result = new Float32Array(newLength);
        var offsetResult = 0;
        var offsetBuffer = 0;
        while (offsetResult < result.length) {
          var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
          var accum = 0,
            count = 0;
          for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
            accum += buffer[i];
            count++;
          }
          result[offsetResult] = accum / count;
          offsetResult++;
          offsetBuffer = nextOffsetBuffer;
        }
        return result;
      };

      processor.addEventListener("audioprocess", () => {});

      processor.onaudioprocess = (ev: AudioProcessingEvent) => {
        var mic = downsample(ev);

        // Convert to 16-bit PCM
        var output = new Uint16Array(mic.length);
        for (var i = 0; i < ev.inputBuffer.getChannelData(0).length; i++) {
          var n = mic[i];
          var v = n < 0 ? n * 32768 : n * 32767;
          var tmp = Math.max(-32768, Math.min(32768, v));
          output[i] = tmp;
        }

        // As byte buffer
        var uint8 = new Uint8Array(output.buffer);

        // Base64 Encode
        var base64 = btoa(uint8.reduce((data, byte) => data + String.fromCharCode(byte), ""));

        // Dispatch
        props.emitter.EmitUIInteraction({
          type: "50",
          action: PCMAudioMessageType,
          data: base64,
        });
      };
      processor.connect(audio_context.destination);
      gainNode.connect(audio_context.destination);
      gainNode.gain.value = 0;
    });
  }, [props.emitter, props.streamerStatus, props.microphone]);
};

export default useSendAudioFrames;
