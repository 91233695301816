import { useEffect, useRef, useState } from "react";
import { Input } from "semantic-ui-react";
import { InputEmitter, StreamerStatus } from "@pureweb/platform-sdk";
import { Subject } from "rxjs";
import { APPSTATES } from "./constants/states";
import { appStatusService } from "./services/appStatusService";
import Checkbox from "./components/Checkbox";
import ScrollViewWrapper from "./ScrollViewWrapper";
import Auth0Form from "./Auth0Form";
import LocalAuth from "./helpers/LocalAuth";
import LocalAuthForm from "./LocalAuthForm";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthenticationState } from "./hooks/useAuthenticationSwitch";
import { Status } from "./hooks/useGrayconTickets";
import useClientTranslation from "./hooks/useClientTranslation";
import { useConverveAuthentication } from "./hooks/useConverveAuthentication";
import useLocalAuth from "./hooks/useLocalAuth";
import usePlatformLanguage from "./hooks/usePlatformLanguage";
import { useLocation } from 'react-router-dom';

interface AuthenticationViewProps {
  check: (code: string) => any;
  status: Status;
  emitter: InputEmitter;
  streamerStatus: StreamerStatus;
  messageSubject: Subject<string>;
  authenticationState: AuthenticationState;
  isAuthenticated: Boolean;
  localAuth: LocalAuth | undefined;
  logout: () => void;
}

export default function AuthenticationView(props: AuthenticationViewProps) {
  const { language } = usePlatformLanguage();
  const { isTranslationSet, translation } = useClientTranslation(language);
  const [isViewUpdated, setIsViewUpdated] = useState<boolean>(false);
  const launchContainer = useRef<HTMLDivElement>(null);
  const { user } = useAuth0();
  const namespace = "https://metaverse.bizzlogic.com/";
  const userMetadata = user?.[namespace + "user_metadata"];
  console.log(user);
  const [username, setUsername] = useState<string | null | undefined>();

  const [showActions, setShowActions] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowActions(true);
    }, 4000);
  }, []);


  const updateIsViewUpdated = (state: boolean) => {
    setIsViewUpdated(state);
  };

  useEffect(() => {
    const getUsername = () => {
      if (!props.isAuthenticated) return;

      switch (props.authenticationState) {
        case AuthenticationState.AUTH0:
          return user ? userMetadata?.Name : null;
        case AuthenticationState.LOCAL:
          return props.localAuth?.name;
        default:
          return;
      }
    };
    setUsername(getUsername);
  }, [props.authenticationState, props.isAuthenticated, props.localAuth, user]);

  useEffect(() => {
    const loadHandler = () => {
      if (launchContainer?.current && isTranslationSet) {
        if (launchContainer.current) {
          launchContainer.current.style.opacity = "1";
        }
      }
    };
  
    window.addEventListener("load", loadHandler);
    return () => {
      document.removeEventListener("load", loadHandler);
    };
  }, [isTranslationSet]);
  

  const Actions = () => {
    return (
      <div className={`flex space-x-20 w-full ${props.authenticationState === AuthenticationState.LOCAL ? "justify-between" : "justify-center"}`}>
        {props.authenticationState === AuthenticationState.LOCAL && (
          <input onClick={() => props.logout()} className="btn btn-white flex-1" type="button" id="logoutBtn" value={translation.launch.actions.logout} />
        )}
        <input
          onClick={() => appStatusService.updateAppStatus(APPSTATES.MICCHECK)}
          className={`btn btn-brown flex-1${props.authenticationState === AuthenticationState.LOCAL ? "" : " md:max-w-1/2"}`}
          type="button"
          placeholder={translation.launch.actions.login}
          value={translation.launch.actions.continueButton}
        />
      </div>
    );
  };

  const Loading = () => {
    return <h3 className="md:text-2xl xxl:4xl">{translation.AuthenticationView.loading}</h3>;
  };

  const Headline = () => {
    return <h1 className="md:text-2xl xxl:4xl">{translation.AuthenticationView.title}</h1>;
  };

  const IntroImage = () => {
    return (
      <div className="h-32 md:h-auto w-53 hidden md:block">
        <img className="object-cover w-full h-screen" src="/intro.jpg" alt="img" />
      </div>
    );
  };

  const Logo = () => {
    return <img alt="Bizzlogic Logo" src="/logo.svg" className="w-389" style={{ marginBottom: "27px" }} />;
  };

  const Subtitle = () => {
    if (props.isAuthenticated) {
      return <h2>{translation.launch.subtitle}</h2>;
    } else {
      return (
        <h2 className="text-center md:text-left" style={{ marginTop: "5px", marginBottom: "35px" }}>
          {props.authenticationState === AuthenticationState.AUTH0 ? translation.launch.subtitle : translation.launch.activate}
        </h2>
      );
    }
  };

  const Welcome = () => {
    return (
      <h1 className="mt-80 text-center">
        {translation.AuthenticationView.welcome} {username + "!"}
      </h1>
    );
  };

  const AuthError = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const error = searchParams.get('error');
    const errorDescription = searchParams.get('error_description');

    if (error === 'unauthorized' && errorDescription) {
      setErrorMessage(decodeURIComponent(errorDescription));
    }
  }, [location]);

  return (
    <div>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );

  };

  return (
    <div id="launchContainer" ref={launchContainer} className="transition duration-1000 opacity-0">
      <div className="flex flex-col md:flex-row w-full min-h-screen md:h-screen">
        <ScrollViewWrapper {...props} isViewUpdated={isViewUpdated} outerStyles={"bg-white md:w-47"} innerStyles={"w-full"}>
          <div className="relative flex flex-col items-center mx-auto z-0 w-90 md:w-80" style={{ maxWidth: "669px" }}>
            <Logo />
            <Headline />
            <Subtitle />
            <AuthError />
            {!props.isAuthenticated ? (
              <AuthenticationForm {...props} isViewUpdated={isViewUpdated} updateIsViewUpdated={updateIsViewUpdated} />
            ) : (
              ((LocalAuth.validCode() && props.authenticationState === AuthenticationState.LOCAL) ||
                props.authenticationState === AuthenticationState.AUTH0) && (
                <>
                  <Welcome />
                  {showActions ? <Actions /> : <Loading />}
                </>
              )
            )}
          </div>
        </ScrollViewWrapper>
        <IntroImage />
      </div>
    </div>
  );
}

const AuthenticationForm = (props: any) => {
  const [code, setCode] = useState<string>("");
  const { language } = usePlatformLanguage();
  const { translation } = useClientTranslation(language);
  const { isAuthenticated, code: converveToken } = useConverveAuthentication(props.authenticationState === AuthenticationState.CONVERVE);
  const { authenticate } = useLocalAuth();

  useEffect(() => {
    if (props.authenticationState !== AuthenticationState.GC || props.status !== Status.OK) return;
  }, [props.status, props.authenticationState]);

  useEffect(() => {
    if (props.authenticationState !== AuthenticationState.CONVERVE) return;

    setCode(converveToken);
  }, [converveToken, isAuthenticated, props.authenticationState]);

  const localAuthSave = (name: string, job: string, occupation: string, interests: string, country: string, companyName: string, email: string) => {
    authenticate(name, job, occupation, interests, country, companyName, email);
    appStatusService.updateAppStatus(APPSTATES.MICCHECK);
  };

  switch (props.authenticationState) {
    case AuthenticationState.AUTH0:
      return <Auth0Form {...props} />;
    case AuthenticationState.CONVERVE:
      return (
        <div id="launchContainer">
          <div id="container">
            <h1>{translation.AuthenticationView.converve.title}</h1>
          </div>
          <div id="codecontainer">
            {!Boolean(code) && <h3 style={{ marginTop: 20 }}>{translation.AuthenticationView.converveNoCode}</h3>}
            {!isAuthenticated() && <h3 style={{ marginTop: 20 }}>{translation.AuthenticationView.converveWrongCode}</h3>}
          </div>
          <br />
          <br />
        </div>
      );
    case AuthenticationState.GC:
      return (
        <div id="launchContainer">
          <div id="container">
            <h1>{translation.AuthenticationView.gc.title}</h1>
          </div>
          <div id="codecontainer">
            <h1>{translation.AuthenticationView.logintitle}</h1>
            <h3>{translation.AuthenticationView.enterCode}</h3>
            <Input type="text" placeholder="Code" onChange={(e) => setCode(e.target.value)} />
            <br />
            {props.status === Status.NOT_FOUND && <h3>{translation.AuthenticationView.noCodeFound}</h3>}
            {props.status === Status.OK && <h3>{translation.AuthenticationView.codeOk}</h3>}
            {/* { !props.loading && props.status !== Status.OK && <Button onClick={() => props.check(code)}>{ translation.AuthenticationView.button }</Button> } */}
            {props.status === Status.OK && code && <LocalAuthForm onSave={localAuthSave} code={code} />}
          </div>
          <br />
          <br />
        </div>
      );
    case AuthenticationState.GOOGLE:
      return (
        <div>
          <button className="btn text-darkblue pt-13 pb-13 focus:outline-brown">
            <img src="google.svg" alt="Google Logo" className="inline-block w-35 h-35 mr-20" />
            Login with Google
          </button>
          <div className="w-full flex items-center mt-20 mb-18">
            <div className="flex-grow h-1 bg-brown"></div>
            <span className="flex-shrink px-13 text-brown-dark">Or login with email</span>
            <div className="flex-grow h-1 bg-brown"></div>
          </div>

          <input type="email" placeholder="Email" name="email" className="btn" />
          <input type="password" placeholder="Password" name="pass" className="btn" />

          <div className="w-full flex flex-col md:flex-row justify-between mt-8 mb-20 space-y-20 md:space-y-0">
            <Checkbox>Keep me logged in</Checkbox>
            <Checkbox>Forgot your password?</Checkbox>
          </div>

          <input
            onClick={() => appStatusService.updateAppStatus(APPSTATES.MICCHECK)}
            className="btn btn-brown cursor-pointer"
            type="submit"
            placeholder={translation.launch.actions.login}
          />

          <div id="signup" className="text-center">
            <span className="text-gray-light">Haven’t sign up yet?</span>{" "}
            <span
              className="text-brown rounded hover:text-brown-dark focus:text-brown-dark focus:outline-brown-narrow"
              role="button"
              tabIndex={0}
              onClick={() => {
                props.updateIsViewUpdated(true);
              }}
              onKeyDown={() => {
                props.updateIsViewUpdated(true);
              }}
            >
              Sign up
            </span>
          </div>
        </div>
      );
    case AuthenticationState.LOCAL:
      return <LocalAuthForm onSave={localAuthSave} {...props} />;
    default:
      return <></>;
  }
};
