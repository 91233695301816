import { Subject } from "rxjs";
import useMillicast from "../hooks/useMillicast";
import { InputEmitter } from "@pureweb/platform-sdk";
import { Confirm } from "semantic-ui-react";
import { useEffect, useState } from "react";
import { System } from "@pureweb/platform-sdk-react";

interface StreamProps {
  emitter: InputEmitter;
  messageSubject: Subject<string>;
}

export default function Stream(props: StreamProps) {
  const { start, stop } = useMillicast(props.emitter);
  const info = System.Browser();
  const isSafari = info.name === "safari";

  const [state, setState] = useState<{ open: boolean; result: string }>({ open: false, result: "null" });
  const [connectOptions, setConnectOptions] = useState<{ token: string; streamId: string; sharingMode: string }>({ token: "", streamId: "", sharingMode: "" });

  const show = () => setState({ open: true, result: "shown" });
  const handleConfirm = () => {
    setState({ result: "confirmed", open: false })
    console.log("start millicast");
    start(connectOptions.token, connectOptions.streamId, connectOptions.sharingMode)
  };
  const handleCancel = () => setState({ result: "cancelled", open: false });

  useEffect(() => {
    const subscription = props.messageSubject.subscribe(
      (value: string) => {
        try {
          let content = JSON.parse(value);
          setConnectOptions({ token: content.token, streamId: content.streamId, sharingMode: content.sharingMode });
          if (content.type === "start_millicast") {
            console.log("start millicast");
            if (isSafari) {
              show()
            } else {
              start(content.token, content.streamId, content.sharingMode);
            }
          } else if (content.type === "stop_millicast") {
            console.log("stop millicast");
            stop();
          }
        } catch (e) {}
      },
      (err: any) => {
        console.error(err);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, [props.messageSubject, state, isSafari, start, stop]);

  return (
    <Confirm
      open={isSafari && state.open}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      content="Do you want to start the stream?"
      cancelButton="No"
      confirmButton="Yes"
    />
  );
}