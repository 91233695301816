import { InputEmitter, StreamerStatus } from "@pureweb/platform-sdk";

import { useEffect } from "react";
import { Subject } from "rxjs";
import ConverveAuth from "../helpers/ConverveAuth";
import ConverveParticipant from "../helpers/ConverveParticipant";

require("dotenv").config();

const VALUE_LISTENER = "converveParticipantExchange";

export const useUnrealConverveParticipantExchange = (emitter: InputEmitter, streamerStatus: StreamerStatus, messageSubject: Subject<string>) => {
  useEffect(() => {
    const emitContent = (content: any) => {
      emitter.EmitUIInteraction(content);
    };

    const cannotEmit = (streamerStatus: StreamerStatus, emitter: InputEmitter): Boolean => {
      return !emitter || streamerStatus !== StreamerStatus.Connected;
    };

    if (cannotEmit(streamerStatus, emitter)) return;

    const subscription = messageSubject.subscribe(
      (value: string) => {
        try {
          let json = JSON.parse(value);

          if (json.type === VALUE_LISTENER) {
            let participant: ConverveParticipant = ConverveAuth.participant;

            emitContent({ firstName: participant.firstName, lastName: participant.lastName, email: participant.email, company: participant.company });
          }
        } catch (e) {
          console.error(e);
        }
      },
      (err: any) => {
        console.error(err);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [messageSubject, emitter, streamerStatus]);
};
