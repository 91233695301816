import { useState} from 'react'
import useAsyncEffect from "use-async-effect";
import PingAuth from '../helpers/PingAuth';

export const usePingAuthentication = (enabled: boolean) => {

  const ping = PingAuth.default()
  const [bearer, setBearer] = useState(PingAuth.getBearer())

  function isAuthenticated(ping: PingAuth): boolean {
    return ping.isAuthenticated()
  }

  useAsyncEffect(async () => {
    if(!enabled || isAuthenticated(ping)) return

    
    if(ping.isCancelUrl()){
      alert("You cancelled the login process!")
      window.location.href = window.location.origin

      return
    }

    if(ping.isRedirectUrl()){
      setBearer(ping.getCode())
      ping.setCode(ping.getCode())

      window.location.href = window.location.origin
    } else {
      /**
        * If User is not authenticated => redirect to Authorize Page from Ping
        */
      if(!ping.isAuthenticated()) {
      await ping.redirectToLogin()
        return 
      }
    }
  }, [])

  return { isAuthenticated: () => isAuthenticated(ping), bearer, cleanUp: () => PingAuth.cleanUp(), logout: () => ping.logout() }
}