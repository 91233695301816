import { Button } from "semantic-ui-react";
import useClientTranslation from "../hooks/useClientTranslation";
import usePlatformLanguage from "../hooks/usePlatformLanguage";

const ServiceUnavailable = () => {
  const { language } = usePlatformLanguage();
  const { translation } = useClientTranslation(language);

  return (
    <div className="flex items-center justify-center flex-col w-full min-h-screen">
      {/* @ts-ignore */}
      <h3 className="mb-30">{translation.LoadingView?.ServiceUnavailable}</h3>
      {/* @ts-ignore */}
    </div>
  );
};

export default ServiceUnavailable;
