import {
  InputEmitter,
  StreamerStatus
} from '@pureweb/platform-sdk';

import { useEffect } from 'react'
import { Subject } from 'rxjs';
import PingAuth from '../helpers/PingAuth';

require('dotenv').config()

const VALUE_LISTENER = 'aoCodeExchange'

interface UnrealTokenExchangeContent {
    code: string,
}

export const useUnrealPingTokenExchange = (emitter: InputEmitter, streamerStatus: StreamerStatus, messageSubject: Subject<string>) => {

  useEffect(() => {
    const emitContent = (content: UnrealTokenExchangeContent) => {
      emitter.EmitUIInteraction(content)
    }
  
    const canEmit = (streamerStatus: StreamerStatus, emitter: InputEmitter): Boolean => {
      return (!emitter || streamerStatus !== StreamerStatus.Connected)
    }

    if (canEmit(streamerStatus, emitter)) return;

    const subscription = messageSubject.subscribe(
      (value: string) => {

        try {
          let json = JSON.parse(value)

          if(json.type === VALUE_LISTENER) {
            let bearer = PingAuth.getBearer()
            PingAuth.cleanUp()
            emitContent({ code: bearer })
          }
        } catch(e){

        }        
      },
      (err: any) => {
        console.error(err);
      }
    );
    
    return () => {
      subscription.unsubscribe();
    };
  }, [messageSubject, emitter, streamerStatus]);
}