import useClientTranslation from "../hooks/useClientTranslation";
import usePlatformLanguage from "../hooks/usePlatformLanguage";
import React from 'react';

const InvalidCodeMessage = () => {
  const { language } = usePlatformLanguage();
  const { translation } = useClientTranslation(language);

  return <h2 className="mt-20 text-center">{translation.AuthenticationView.localAuth.form.invalidToken}</h2>;
};

export default InvalidCodeMessage;